<template>
  <div class="blank">
    <VToolbar dense>
      <VBtn text class="px-0" style="min-width: 36px">
        <VIcon>mdi-arrow-left</VIcon>
      </VBtn>
      <VDivider vertical />
      <VSpacer />
      <VBtnToggle dense borderless multiple :max="0">
        <VBtn><VIcon>mdi-chevron-left</VIcon></VBtn>
        <VBtn>A123456789 王小明</VBtn>
        <VBtn><VIcon>mdi-chevron-right</VIcon></VBtn>
      </VBtnToggle>
      <VBtnToggle dense borderless multiple :max="0">
        <VBtn><VIcon>mdi-chevron-left</VIcon></VBtn>
        <VBtn>2021年07月01日</VBtn>
        <VBtn><VIcon>mdi-chevron-right</VIcon></VBtn>
      </VBtnToggle>
      <VSpacer />
      <VDivider vertical />
      <VBtn depressed color="primary">儲存</VBtn>
    </VToolbar>
    <VContainer fluid>
      <VForm>
        <VContainer>
          <!-- TODO -->
        </VContainer>
      </VForm>
    </VContainer>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Blank',
  data: () => ({
    // TODO
  }),
});
</script>

<style lang="scss">
.blank {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }

  > .container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0px;
    .v-form {
      .container {
        max-width: 800px;
        width: 800px;
        padding: 8px;
        .row {
          margin: -4px;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }

        .col {
          padding: 4px;
        }

        .v-card {
          padding: 8px;
          .v-divider {
            padding: 4px;
          }

          .row {
            margin: -4px;
          }

          .col {
            padding-top: 12px;
            .v-input {
              padding-top: 0px;
            }
          }

          th,
          td {
            min-width: 80px;
            text-align: center;
            padding: 0px;
            .v-input {
              border-radius: 0px;
              &:not(.v-input--is-focused)
                > .v-input__control
                > .v-input__slot:not(:hover):not(:active)
                > fieldset {
                color: transparent;
              }
            }
          }

          $border-colors: (
            'theme--light': rgba(0, 0, 0, 0.12),
            'theme--dark': rgba(255, 255, 255, 0.12),
          );
          @each $theme, $border-color in $border-colors {
            .#{$theme}.v-data-table > .v-data-table__wrapper > table > {
              thead,
              tbody {
                > tr > {
                  td,
                  th {
                    &:not(:last-child) {
                      border-right: thin solid $border-color;
                    }
                  }
                }

                > tr:hover {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
